import React, { FC } from 'react';
import { graphql } from 'gatsby';

import Container from 'layout/Container';
import Layout from 'layout/Layout';
import PageIntro from 'components/elements/PageIntro';
import DangerouslySetInnerHtml from 'components/helpers/DangerouslySetInnerHtml';

import { IPropsLdsPage } from './models';

import './LdsPage.scss';

const LdsPage: FC<IPropsLdsPage> = ({ data: { pageData }, pageContext }) => {
  const { seo, urls, title, ldsSettings } = pageData;
  const { breadcrumbs, lang } = pageContext;

  return (
    <Layout data-testid="LdsPage" seo={seo} lang={lang} urls={urls} className="lds-page">
      <PageIntro title={title} breadcrumbs={breadcrumbs} />

      {ldsSettings?.[0]?.body ? (
        <Container className="lds-container">
          <DangerouslySetInnerHtml className="lds-wrapper" html={ldsSettings[0].body} />
        </Container>
      ) : null}
    </Layout>
  );
};

export const query = graphql`
  query ($url: String!, $lang: String!) {
    pageData: ldsPage(url: { eq: $url }, lang: { eq: $lang }) {
      ...FragmentLdsPage
    }
  }
`;

export default LdsPage;
